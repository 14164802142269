<template>
    <Layout>
        <form id="form" novalidate>
            <div class="row">

                <div class="col-md-8">

                    <div class="page-separator">
                        <div class="page-separator__text">Informações Básicas</div>
                    </div>

                    <input type="hidden" v-model="formData.id">

                    <label class="form-label">Título da Mentoria <span class="text-danger">*</span></label>
                    <div class="form-group mb-24pt">
                        <input type="text" class="form-control form-control-lg" placeholder="Título da Mentoria"
                               v-model="formData.title" required>
                    </div>

                    <div class="form-group forms mb-64pt">

                        <label class="form-label justify-content-between d-flex">
                                <span>
                                    Resumo
                                    <span class="fs-10 text-muted">Máximo 1000 caracteres</span>
                                </span>
                        </label>
                        <vue-editor ref="editor2" class="editorSize" @input="checkLength" v-model="formData.resume"
                                    required
                                    :editor-toolbar="customToolbar"/>
                        <b-badge :variant="formData.resume.length <= 700 ? 'success' : (
                            formData.resume.length > 700 && formData.resume.length <= 980 ? 'warning' : 'danger'
                        )"
                                 class="text-white mx-auto contador bold fs-12 mt-5">
                            {{ formData.resume.length }}/1000
                        </b-badge>
                    </div>


                    <div class="form-group forms mb-64pt">
                        <div class="d-flex justify-content-between">
                            <label class="form-label justify-content-between d-flex">
                                <span>
                                    Descrição
                                </span>
                            </label>
                            <div class="d-flex">
                                <button class="btn btn-outline-info btn-sm mb-1" id="buttonGenerate" type="button"
                                        @click.stop="generateDescription">
                                    Gerar automaticamente
                                </button>
                                <i v-b-tooltip.hover.right="'Gerar a descrição da mentoria via inteligência artificial de acordo com o título e o resumo.'"
                                   class="fa fa-info-circle ml-2 my-auto"></i>
                            </div>
                        </div>
                        <vue-editor ref="editor1" class="editorSize" v-model="formData.description" required
                                    :editorToolbar="customToolbar"/>
                    </div>

                    <div class="form-group forms mb-64pt">
                        <label class="form-label">Termos de Uso da Mentoria
                            <i v-b-tooltip.hover.right="'Descreva aqui as orientações gerais da mentoria.'"
                               class="fa fa-info-circle"></i>
                        </label>
                        <vue-editor ref="editor3" class="editorSize" v-model="formData.policy" required
                                    :editor-toolbar="customToolbar"/>
                    </div>


                </div>

                <div class="col-md-4">
                    <button type="button" @click="goBack"
                            class="btn btn-primary btn-save" style="right:150px;">
                        Voltar
                    </button>
                    <button type="button" @click.prevent="save(false)" id="save" class="btn btn-primary btn-save">Salvar
                    </button>

                    <div class="card" v-if="formData.id > 0">
                        <div class="list-group list-group-flush">
                            <div class="list-group-item text-center">
                                <button v-if="!formData.status" type="button" class="btn btn-outline-success btn-block"
                                        @click="save(true)">
                                    Ativar
                                </button>
                                <button v-else type="button" class="btn btn-outline-danger btn-block" @click="inactive">
                                    Inativar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="page-separator">
                        <div class="page-separator__text">Logo</div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <img v-if="!show && imgCropped.length > 0" :src="imgCropped" id="mentoring_img"
                                 class="mentor-imagem" alt="nova_logo_mentoria">
                            <img v-else-if="!show && formData.photo.length > 0" class="mentor-imagem"
                                 :src="formData.photo"
                                 id="mentoring_img" alt="logo_mentoria">
                            <img v-else-if="!show && formData.photo.length === 0" class="mentor-imagem"
                                 src="../../../assets/images/img01.jpg" id="mentoring_img" alt="logo_mentoria">
                            <Cropper v-if="show" :img="img" :proportion="1" @set-img="setImg" @reset-logo="resetLogo"
                                     ref="cropper"/>
                        </div>
                        <div class="card-body">
                            <label class="form-label">Logo</label>
                            <input type="file" id="logo" class="form-control" @change="handleImageChange" required
                                   accept="image/png, image/jpeg">
                        </div>

                    </div>

                    <div class="page-separator">
                        <div class="page-separator__text">Vídeo de Apresentação</div>
                    </div>

                    <div class="card">
                        <div class="embed-responsive embed-responsive-16by9"
                             v-if="formData.video && formData.video.length > 0 && formData.video.includes('youtube')">
                            <iframe class="embed-responsive-item" :src="getUrlVideo(formData.video)"
                                    allowfullscreen=""></iframe>
                        </div>
                        <div class="card-body">
                            <label class="form-label">Link do Vídeo
                                <i v-b-tooltip.hover.right="'Link deve ser do YouTube.'" class="fa fa-info-circle"></i>
                            </label>
                            <input type="text" class="form-control" v-model="formData.video" required
                                   placeholder="Insira uma URL de vídeo">
                        </div>
                    </div>

                    <div class="page-separator">
                        <div class="page-separator__text">Opções</div>
                    </div>

                    <div class="card">
                        <div class="card-body">

                            <div class="form-group">
                                <label class="form-label">Cor da Mentoria</label>
                                <input type="color" class="form-control" placeholder="Cor da Mentoria"
                                       v-model="formData.color">
                            </div>

                            <div class="form-group">
                                <label class="form-label">Formulário de Aplicação</label>
                                <select name="category" class="form-control" v-model="formData.application_form">
                                    <option value="">Selecionar</option>
                                    <option value="1">Sim</option>
                                    <option value="0">Não</option>
                                </select>
                            </div>

                            <div class="form-group" v-if="formData.application_form == '1'">
                                <label class="form-label">Formulário</label>
                                <select name="category" class="form-control" v-model="formData.application_form_id">
                                    <option value="">Selecionar</option>
                                    <option v-for="form in forms" :value="form.id"
                                            :key="`select-form-application-${form.id}`">
                                        {{ form.title }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label class="form-label">Tipo</label>
                                <select name="category" class="form-control" v-model="formData.type_id">
                                    <option value="">Selecionar</option>
                                    <option value="1">Individual</option>
                                    <option value="2">Em grupo</option>
                                </select>
                            </div>

                            <div class="form-group" v-if="formData.type_id == '2'">
                                <label class="form-label">Vagas</label>
                                <input type="number" class="form-control" placeholder="Vagas"
                                       v-model="formData.vacancies">
                            </div>

                            <div class="form-group">
                                <label class="form-label">Preço</label>
                                <div class="row">
                                    <div class="col">
                                        <div class="input-group form-inline">
                                            <span class="input-group-prepend"><span
                                                class="input-group-text">R$</span></span>
                                            <input type="text" class="form-control" id="price" required
                                                   @keyup="formatMoneyDecimal('price', false)" v-model="formData.price">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="d-flex justify-content-between">
                                    <label class="form-label">Palavras Chave</label>
                                    <div>
                                        <button class="btn btn-outline-info btn-sm mb-1" id="buttonGenerate"
                                                type="button"
                                                @click.stop="generateTags">
                                            Gerar automaticamente
                                        </button>
                                        <i v-b-tooltip.hover.right="'Gerar as Palavras chave de acordo com a Descrição da Mentoria'"
                                           class="fa fa-info-circle ml-1 my-auto"></i>
                                    </div>
                                </div>
                                <input type="text" class="form-control" required @keydown.enter="addTag()"
                                       placeholder="Precione Enter para confirmar" v-model="tag">
                                <span class="fs-10 text-muted ">Máximo 5</span>

                                <div class="tag mt-2" v-for="(tag, index) in tags" :key="index">
                                    {{ tag.tag }}
                                    <span class="remove-tag" @click="removeTag(index)">×</span>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="page-separator">
                        <div class="page-separator__text">Módulos</div>
                        <i style="color:black"
                           v-b-tooltip.hover.right="'Aqui é criado o plano de conteúdo da mentoria, informe os módulos e os itens(conteúdos). Podendo ser vídeo, arquivo, formulário, atividade, agendamento ou leitura.'"
                           class="fa fa-info-circle"></i>
                    </div>

                    <div class="accordion" role="tablist"
                         v-if="(newSections.length > 0) || sections.length > 0 || showForm">

                        <template>
                            <div v-for="(section, index) in sections" :key="index">

                                <Section :newSection="section" :index="index"
                                         @update-forms="updateForms"
                                         @deleteSection="deleteSection(index)"/>
                            </div>
                        </template>

                    </div>

                    <div class="row" v-else>
                        <div class="col">
                            <div class="card">
                                <div class="card-body card-title">
                                    Nenhuma Módulo Cadastrada
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="button" class="btn btn-outline-primary mb-24pt mb-sm-0"
                            @click="addSection">
                        Adicionar Módulo
                    </button>
                </div>
            </div>
        </form>
    </Layout>

</template>

<script>
import Layout from '@/components/layout/main.vue';
import Section from "@/views/mentoring/register/section.vue"
import {VueEditor} from "vue2-editor";
import {endLoading, startLoading} from "@/components/composables/spinners";
import {toFormData, ValidateForm} from "@/components/composables/cruds";
import {moneyToFloat} from "@/components/composables/masks";
import http from "@/http"
import {getUrlVideo} from "@/components/composables/functions";
import Cropper from "@/components/base/cropper.vue";
import {messageSwal} from "@/components/composables/messages";
import _ from 'lodash';

export default {
    components: {
        Layout,
        Section,
        VueEditor,
        Cropper,
    },

    data() {
        return {
            customToolbar: [
                [{'size': ['small', false, 'large', 'huge']}],
                ["bold", "italic"],
                [{list: "ordered"}, {list: "bullet"}],
                [{color: []}, {background: []}],
            ],
            show: false,
            showForm: false,
            showList: false,
            showSpinner: false,
            img: '',
            imgCropped: '',
            selectedFile: null,
            copy: {
                id: 0,
                title: '',
                resume: '',
                description: '',
                policy: '',
                sections: [],
                logo: '',
                video: '',
                category_id: '',
                type_id: '',
                link: '',
                price: '',
                status: 0,
                photo: '',
                vacancies: '',
                application_form_id: '',
                application_form: '',
                color: '',
            },
            formData: {
                id: 0,
                title: '',
                resume: '',
                description: '',
                policy: '',
                sections: [],
                logo: '',
                video: '',
                category_id: '',
                type_id: '',
                link: '',
                price: '',
                status: 0,
                photo: '',
                vacancies: '',
                application_form_id: '',
                application_form: '',
                color: '',
            },
            forms: [],
            categories: [],
            tag: '',
            tags: [],
        }
    },
    methods: {

        updateForms(payload) {
            this.forms = payload
        },

        formatMoneyDecimal(id, money = true) {
            const value = document.getElementById(id).value;
            console.log('entrou')
            // Remove todos os caracteres que não sejam dígitos
            if (value === '') return;

            var cleanValue = value.replace(/\D/g, '');

            // Divide o valor em parte inteira e decimal
            var integerPart = cleanValue.substring(0, cleanValue.length - 2);
            const verify = integerPart.charAt(0);
            if (parseInt(integerPart) > 1 && verify[0] === '0') integerPart = integerPart.slice(1);
            if (integerPart === '' || integerPart == 0) integerPart = '0';
            var decimalPart = cleanValue.substring(cleanValue.length - 2);
            if (decimalPart.length < 2) decimalPart = '0' + decimalPart;

            // Adiciona separador de milhar
            integerPart = integerPart.replace(/(\d)(?=(\d{3})+$)/g, '$1.');

            let format = '';
            if (integerPart === '0' && decimalPart === '00') return document.getElementById(id).value = format;

            if (money) {
                // Formata o valor como moeda (adiciona símbolo de moeda e sinal de negativo)
                format = 'R$ ' + integerPart + ',' + decimalPart;
            } else {
                // Formata o valor em decimal
                format = integerPart + ',' + decimalPart;
            }

            this.formData.price = format;
        },

        initializeEditors() {
            const editorRefs = ['editor1', 'editor2', 'editor3'];
            editorRefs.forEach(ref => {
                const editor = this.$refs[ref].quill;
                editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                    delta.ops.forEach(op => {
                        if (op.attributes) {
                            if ('color' in op.attributes) {
                                delete op.attributes.color;
                            }
                            if ('background' in op.attributes) {
                                delete op.attributes.background;
                            }
                        }
                    });
                    return delta;
                });
            });
        },

        async goBack() {
            const deep = {
                ...this.formData,
                sections: this.$store.state.settingsMentoring.sections,
                tags: this.tags
            }
            if (_.isEqual(deep, this.copy)) {
                this.$router.go(-1);
            } else {
                const result = await messageSwal(
                    "Informações não salvas",
                    "Você realizou alteração a sua mentoria e não as salvou, tem certeza que deseja continuar?",
                    "warning"
                )

                if (result) {
                    this.$router.go(-1);
                }
            }
        },

        deleteSection(index) {

            if (this.sections[index].id) {

                http.delete('mentorings/delete-section/' + this.sections[index].id)
                    .then(response => {
                        this.$notifySuccess(response.data.message);
                    })
            }

            this.sections.splice(index, 1);
        },

        addSection() {
            this.sections.push({

                id: null,
                title: '',
                authorized: 1,
                contents: [{
                    subject: '',
                    type: '',
                    form_id: '',
                    video: '',
                    doc: '',
                    activity: {
                        id: new Date().getTime() + '_activity',
                        description: '',
                        type: '',
                        alternatives: []
                    },
                    return_period: '',
                    deadline: '',
                    position: '',
                    description: '',
                }]

            })
        },
        getUrlVideo,

        addTag() {
            if (this.tags.length > 4) {
                this.$notifyError('Máximo cinco palavras chave');
            } else {
                this.tags.push({
                    tag: this.tag
                });
                this.tag = '';
            }
        },

        removeTag(index) {

            let tag = this.tags[index]

            // se a tag tem ID precisa deletar do banco
            if (tag.id) {
                http.delete('mentorings/tags/' + tag.id)
            }
            this.tags.splice(index, 1);

        },

        setImg(blob) {
            this.imgCropped = URL.createObjectURL(blob);
            this.formData.logo = new File([blob], "cropped-image.png", {type: 'image/png'});
            this.show = false;
        },

        resetLogo() {
            document.getElementById('logo').value = '';
            this.formData.logo = '';
            this.imgCropped = '';
            this.show = false;
        },

        checkLength() {
            const length = this.formData.resume.length;
            if (length > 1000) {
                this.formData.resume = this.formData.resume.substr(0, 1000);
            }
        },

        inactive() {
            startLoading('form', 'buttonGenerate');
            this.$store.commit('api/setSpinner', true);
            http.get('mentorings/change-status/' + this.formData.id)
                .then(response => {
                    this.$notifySuccess(response.data);
                    this.formData.status = 0;
                })
                .catch(errors => {
                    console.log('Inactive Mentoring: ', errors.response.data.message);
                    this.$notifyError(errors);
                })
                .finally(() => {
                    endLoading('form', 'buttonGenerate');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        handleImageChange(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.img = e.target.result;
                    this.show = true;
                };
                reader.readAsDataURL(input.files[0]);
            } else {
                this.show = false;
                this.formData.logo = '';
                this.imgCropped = '';
            }
        },

        generateDescription() {
            const content = this.formData.title + this.formData.resume;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-resume', {content})
                .then(response => {
                    this.formData.description = response.data[0];
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

        generateTags() {
            const content = this.formData.description;
            this.$store.commit('api/setSpinner', true);
            http.post('mentorings/generate-tags', {content})
                .then(response => {
                    response.data.forEach(el => {
                        this.tags.push({
                            tag: el
                        })
                    })
                })
                .catch(e => {
                    console.error('Erro na requisição GPT:', e);
                })
                .finally(() => {
                    this.$store.commit('api/setSpinner', false);
                })
        },

        async save(active) {
            let result = true;


            if (result) {

                startLoading('form', 'save');
                this.$store.commit('api/setSpinner', true);
                let required = {};

                // inserir campos obrigatórios no array
                if (active) {
                    required = {
                        title: 'Título',
                        resume: 'Resumo',
                        description: 'Descrição',
                        policy: 'Políticas da Mentoria',
                        logo: 'Logo',
                        video: 'Vídeo',
                        type_id: 'Tipo',
                        price: 'Preço'
                    };

                    if (this.formData.photo && this.formData.photo.length > 0) {
                        delete required.logo;
                    }

                } else {
                    required = {
                        title: 'Título',
                    };
                }

                // validando os campos do formulário
                if (!ValidateForm(this.$root, this.formData, required)) {
                    endLoading('form', 'save');
                    this.$store.commit('api/setSpinner', false);
                    return;
                }

                // passa o sections da store para o formData
                this.formData.sections = this.$store.state.settingsMentoring.sections

                // validando os conteúdos
                const hasEmptyFields = this.formData.sections.some(section => {
                    return section.contents.some(content => {
                        if (
                            content.type === '' ||
                            content.subject === '' ||
                            (content.type == 1 && (content.video === null || content.video === '')) ||
                            (content.type == 2 && (content.doc === null || content.doc === '')) ||
                            (content.type == 3 && (content.form_id === null || content.form_id === '')) ||
                            (content.type == 4 && (
                                content.activity.type === null || content.activity.type === '' ||
                                content.activity.description === null || content.activity.description === '' ||
                                ((content.activity.type == 2 || content.activity.type == 3) && content.activity.alternatives.length === 0)
                            )) ||
                            (content.type == 6 && (content.description === null || content.description === ''))
                        ) {
                            this.$notifyError('Preencha todos os campos dos conteudos!');
                            endLoading('form', 'save');
                            this.$store.commit('api/setSpinner', false);
                            return true;
                        }
                        return false;
                    });
                });

                if (hasEmptyFields) {
                    return;
                }

                // inserindo os campos do formulário em um FormData
                const data = toFormData(this.formData);

                // convertendo string de preço em float
                data.append('price', moneyToFloat(document.getElementById('price').value));

                // salvando os arquivos em um array de arquivos e salvando a chave do array dentro da Módulo
                this.formData.sections.forEach((element, index) => {

                    element.contents.forEach((content, i) => {
                        if (content.doc) {
                            data.append(`docs[${index}${i}]`, content.doc);
                            this.formData.sections[index].contents[i].docKey = `${index}${i}`;
                        }
                    })
                })

                // convertendo as seções em json para enviar em um array
                data.append('sections', JSON.stringify(this.formData.sections));

                // salvando as tags em array
                if (this.tags.length > 0) {
                    this.tags.forEach(tag => {
                        if (!tag.id && tag.tag)
                            data.append('tags[]', tag.tag);
                    })
                }

                // se for ativar a mentoria, inserir status 1
                if (active) data.append('status', 1);

                let url = 'mentorings';

                if (this.formData.id > 0) {
                    url += `/${this.formData.id}`;
                    data.append('_method', 'PUT');
                }

                http.post(url, data)
                    .then(response => {
                        this.showSpinner = false;
                        if (!active) {
                            this.$notifySuccess(response.data);
                        } else {
                            this.formData.status = 1;
                            this.$notifySuccess('Mentoria ativada com sucesso!');
                        }

                        this.copy = JSON.parse(JSON.stringify(this.formData));
                        this.$router.push('/mentorias')
                    })
                    .catch(e => {
                        console.error('Mentoring Register: ', e.response.data);
                        this.$notifyError(e)
                    })
                    .finally(() => {
                        endLoading('form', 'save');
                        this.$store.commit('api/setSpinner', false);
                    })
            }
        },

        getData(id) {
            startLoading('form', 'save');
            this.$store.commit('api/setSpinner', true);
            http.get('mentorings/' + id, {
                    headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
                })
                .then(response => {
                    const keys = Object.keys(response.data);

                    keys.forEach(key => {
                        this.formData[key] = response.data[key] ?? '';
                    })

                    this.tags = response.data.tags

                    const sections = response.data.sections;
                    sections.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

                    this.$store.commit('settingsMentoring/setSections', sections)
                    this.formData.sections = [];

                    if (this.formData.price) this.formData.price = this.formData.price.replace('.', ',')
                    this.show = false;

                    this.copy = JSON.parse(JSON.stringify(this.formData));
                    this.copy.sections = JSON.parse(JSON.stringify(sections));
                    this.copy.tags = JSON.parse(JSON.stringify(this.tags));

                    if (response.data.photo !== '') document.getElementById('logo').required = false;

                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    endLoading('form', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

    },
    mounted() {
        this.$store.commit('settingsMentoring/resetValues');
        const id = this.$route.params.id;
        if (id) this.getData(id);
        this.initializeEditors();
    },
    computed: {
        sections() {
            return this.$store.state.settingsMentoring.sections;
        },
        newSections() {
            return this.$store.state.settingsMentoring.newSections;
        }
    }
}
</script>

<style scoped>
.editorSize {
    height: 160px;
}

.tag {
    background-color: #efefef;
    margin: 2px;
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.remove-tag {
    margin-left: auto;
    cursor: pointer;
}

.mentor-imagem {
    width: 100%;
    height: 400px;
}
</style>
