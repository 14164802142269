<template>
    <div>
        <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1">
                <div class="p-2">
                    <div class="row">
                        <div class="col-11">
                            <div class="row">
                                <div class="col-3 mb-3">
                                    <label class="form-label">Título do Módulo</label>
                                    <input type="text" v-model="newSection.title" class="form-control"
                                           placeholder="Título da Módulo" required>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label class="form-label d-flex">Prazo Mentorado
                                        <i v-b-tooltip.hover.right="'Prazo em dias para término da módulo'"
                                           class="fa fa-info-circle ml-2 my-auto"></i>
                                    </label>
                                    <input type="number" v-model="newSection.deadline"
                                           class="form-control" placeholder="Prazo em dias" required>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label class="form-label d-flex">Prazo Mentor
                                        <i v-b-tooltip.hover.right="'Prazo em dias para retorno do mentor a contar a partir da finalização do mentorado'"
                                           class="fa fa-info-circle ml-2 my-auto"></i>
                                    </label>
                                    <input type="number" v-model="newSection.return_period"
                                           class="form-control" placeholder="Prazo em dias" required>
                                </div>
                                <div class="col-md-3 mt-auto mb-4">
                                    <div class="custom-control custom-checkbox">
                                        <input :id="'check' + index" type="checkbox"
                                               class="custom-control-input"
                                               v-model="newSection.authorized">
                                        <label :for="'check' + index"
                                               class="custom-control-label">
                                            Liberado
                                        </label>
                                        <i v-b-tooltip.hover.right="'Se este campo estiver selecionado o aluno terá acesso ao módulos e seus conteúdos a partir da sua data de inscrição a mentoria.'"
                                           class="fa fa-info-circle ml-2"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 my-auto">
                            <i class="fa fa-trash text-danger pointer" title="Excluir Conteúdo"
                               @click="removeSection()"></i>
                        </div>
                    </div>
                </div>
            </b-card-header>
            <b-card-body>
                <div class="row">
                    <div class="col">
                        <label class="form-label">Conteúdos:</label>
                    </div>
                </div>
                <draggable v-model="newSection.contents">
                    <div class="row border-bottom mb-3" style="cursor:grab"
                         v-for="(content, ind) in newSection.contents"
                         :key="'subSection-' + ind">

                        <div class="col-11">
                            <div class="row">
                                <div class="col-md-4 d-flex mb-3">
                                    <i
                                        class="material-icons text-70 icon-16pt icon--left mt-auto mb-2">drag_handle</i>
                                    <div class="w-100">
                                        <label>Assunto</label>
                                        <input type="text" class="form-control" required
                                               placeholder="Assunto da Atividade/Conteúdo"
                                               v-model="content.subject">
                                    </div>
                                </div>

                                <div class="col-md-2 mb-3">
                                    <label>Tipo</label>
                                    <select class="form-control" required v-model="content.type">
                                        <option value="">Selecionar</option>
                                        <option value="1">Vídeo</option>
                                        <option value="2">Arquivo</option>
                                        <option value="3">Formulário</option>
                                        <option value="4">Atividade</option>
                                        <option value="5">Agenda</option>
                                        <option value="6">Leitura</option>
                                    </select>
                                </div>

                                <div class="col-md-5 mb-3" v-if="content.type == 1">
                                    <label>Link</label>
                                    <input class="form-control mr-2" type="text" placeholder="Link do Vídeo"
                                           v-model="content.video" required>
                                </div>

                                <div class="col-md-4 mb-3" v-if="content.type == 2">
                                    <label v-if="!content.url">Arquivo</label>
                                    <label v-else>Trocar Arquivo</label>
                                    <input class="form-control mr-2"
                                           type="file" required :id="'doc-' + ind"
                                           @change="handleFileUpload"
                                           v-if="content.type == 2">
                                </div>
                                <div class="col-md-2 mb-4 mt-auto" v-if="content.type == 2 && content.url">

                                    <a :href="content.url" data-toggle="tooltip" :title="content.doc"
                                       class="content-file" target="_blank">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </div>


                                <div class="col-md-4 mb-3" v-else-if="content.type == 3">
                                    <label>Formulário</label>
                                    <select class="form-control mr-2" required v-model="content.form_id"
                                            v-if="content.type == 3">
                                        <option value="">Selecione o Formulário</option>
                                        <option v-for="form in forms" :key="'form-option-' + form.id"
                                                :value="form.id">
                                            {{ form.title }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 mb-4 mt-auto" v-if="content.type == 3 && content.form_id !== ''">
                                    <a :href="`${env.menteeUrl}formulario/preview/${encondeId(content.form_id)}`"
                                       target="_blank">
                                        <i class="fa fa-search text-muted mx-1" title="Preview"></i>
                                    </a>
                                </div>
                                <div class="col-md-12 mb-3" v-else-if="content.type == 6">
                                    <label>Descrição</label>
                                    <vue-editor class="editorSize" v-model="content.read"
                                                required :editor-toolbar="customToolbar"/>
                                </div>

                                <div style="display: contents" v-else-if="content.type == 4">
                                    <div class="col-md-12 mb-3">
                                        <label>Descrição</label>
                                        <vue-editor class="editorSize" v-model="content.activity.description"
                                                    required :editor-toolbar="customToolbar"/>
                                    </div>

                                    <div class="col-md-4 mb-3">
                                        <label class="form-label">Tipo <span class="text-danger">*</span></label>
                                        <select name="category" v-model="content.activity.type" class="form-control">
                                            <option value="">Selecione</option>
                                            <option value="1">Dissertativa</option>
                                            <option value="2">Única escolha</option>
                                            <option value="3">Multipla escolha</option>
                                            <option value="4">Upload de arquivo</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 mb-3"
                                         v-if="content.activity.type == '2' || content.activity.type == '3'">
                                        <label class="form-label">Alternativas </label>
                                        <input
                                            class="form-control"
                                            v-model="currentAlternative"
                                            @keyup.enter="addAlternative(ind)"
                                            @keydown.tab.prevent="addAlternative(ind)"
                                            placeholder="Digite a alternativa e precione Enter  OU Tab..."
                                        />

                                        <div class="tag mt-2"
                                             v-for="(alternative, index) in content.activity.alternatives"
                                             :key="index">
                                            <span v-if="typeof alternative === 'object' ">{{
                                                    alternative.alternative
                                                }}</span>
                                            <span v-else>{{ alternative }}</span>
                                            <span class="remove-tag" @click="removeAlternative(ind, index)">×</span>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-1 my-auto">
                            <i class="fa fa-trash text-danger ml-2 my-auto pointer ml-auto"
                               title="Excluir Conteúdo" @click="removeContent(ind)"></i>
                        </div>
                    </div>
                </draggable>
                <div class="row">
                    <div class="col-12 text-center mt-3">

                        <i class="fa fa-plus-circle text-muted fa-2x pointer text-center"
                           title="Adicionar Conteúdo" @click="addContent()"></i>

                    </div>
                </div>
            </b-card-body>
        </b-card>

    </div>
</template>

<script>


import http from "@/http";
import draggable from 'vuedraggable'
import {VueEditor} from "vue2-editor";
import {encondeId} from "@/components/composables/functions";
import env from "@/env";

export default {
    computed: {
        env() {
            return env
        }
    },
    data() {
        return {
            showIcons: false,
            forms: {},
            customToolbar: [
                [{'size': ['small', false, 'large', 'huge']}],
                ["bold", "italic"],
                [{list: "ordered"}, {list: "bullet"}],
                [{color: []}, {background: []}],
            ],
            currentAlternative: ''
        }
    },
    components: {
        draggable,
        VueEditor,
    },
    props: [
        'newSection',
        'index'
    ],
    methods: {
        encondeId,

        getForms() {
            http.get('forms/?paginated=false')
                .then(response => {
                    this.forms = response.data.data
                    this.$emit('update-forms', response.data.data);
                })
        },

        addContent() {
            this.newSection.contents.push({
                id: null,
                subject: '',
                type: '',
                form_id: '',
                video: '',
                doc: '',
                activity: {
                    id: new Date().getTime() + '_activity',
                    description: '',
                    type: '',
                    alternatives: []
                },
                return_period: '',
                deadline: '',
                position: '',
                read: '',
            });
        },

        removeContent(index) {
            if (this.newSection.contents.length > 1) {

                if (this.newSection.contents[index].id) {
                    http.delete('mentorings/delete-content/' + this.newSection.contents[index].id)
                        .then(response => {
                            this.$notifySuccess(response.data.message);
                        })
                }

                this.newSection.contents.splice(index, 1);
            } else {
                this.$notifyError('A módulo precisa ter ao menos 1 conteúdo');
            }
        },

        removeSection() {

            this.$emit('deleteSection', this.index)

        },

        addSection() {
            this.$store.commit('settingsMentoring/setNewSections', this.newSection);

            setTimeout(() => {

                this.newSection = {
                    id: null,
                    title: '',
                    authorized: 1,
                    contents: [{
                        subject: '',
                        type: '',
                        form_id: '',
                        video: '',
                        doc: '',
                        activity: '',
                        activity_type: '',
                        return_period: '',
                        deadline: '',
                        position: '',
                        description: '',
                        alternatives: []
                    }]
                }
            }, 250)
        },

        // Adicionar novas alternativas
        addAlternative(index) {
            const now = new Date();
            if (this.currentAlternative.trim() !== '' && !this.newSection.contents[index].activity.alternatives.includes(this.currentAlternative)) {
                this.newSection.contents[index].activity.alternatives.push({
                        id: now.getTime() + '_alternative',
                        alternative: this.currentAlternative.trim()
                    }
                );
                this.currentAlternative = '';
            }
        },

        // Removendo alternativa
        removeAlternative(i, index) {
            const id = String(this.newSection.contents[i].activity.alternatives[index].id);
            if (id.indexOf('_alternative') < 0) {
                http.delete('activities/delete-alternatives/' + id)
                    .then(response => {
                        this.$notifySuccess(response.data);
                    })
                    .catch(e => {
                        this.$notifyError('Erro ao excluir alternativa!');
                        console.error(e);
                        return;
                    })
            }

            this.newSection.contents[i].activity.alternatives.splice(index, 1);
        },

        handleFileUpload(event) {
            const arr = event.target.id.split('-');
            this.newSection.contents[arr[1]].doc = event.target.files[0];
        },

    },

    created() {
        this.getForms();
    },

    mounted() {
        this.newSection.contents = this.newSection.contents.slice().sort((a, b) => a.position - b.position);
    }
}

</script>

<style scoped>

.content-file {
    margin-top: 15px;
    display: block;
}

.ql-container.ql-snow {
    height: 300px !important;
}

.textareaSize {
    width: 100%;
    height: 80px;
}

.alternatives-input-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
}

.tag {
    background-color: #efefef;
    margin: 2px;
    padding: 5px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.remove-tag {
    margin-left: auto;
    cursor: pointer;
}
</style>

