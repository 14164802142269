<template>
    <div class="row">
        <div class="col text-end">
            <cropper
                ref="cropper"
                class="cropper"
                :src="img"
                :stencil-props="{
                    aspectRatio: proportion,
                    movable: true,
                    resizable: true,
                }"
                
            />
            <button @click="cancelCrop" type="button" class="btn btn-outline-danger mt-3">Cancelar</button>
            <button @click="cropImage" type="button" class="btn btn-outline-info mt-3 ml-2">Recortar</button>
        </div>
    </div>
</template>

<script>
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
        Cropper
    },

    props: {
        img: {
            required: true
        },
        proportion: {
            required: true,
            type: Number
        }
    },

    methods: {
        cropImage() {

            const { canvas } = this.$refs.cropper.getResult();

            canvas.toBlob((blob) => {
				// Do something with blob: upload to a server, download and etc.
                this.$emit('set-img', blob);
			});

            

            // const {coordinates, canvas} = this.$refs.cropper.getResult();
            /*
            if (coordinates && canvas) {
                // Cria um novo canvas
                // const croppedCanvas = document.createElement('canvas');
                // const ctx = croppedCanvas.getContext('2d');

                
                
                
                const maxWidth = canvas.width;
                const maxHeight = canvas.height;

                let {left, top, width, height} = coordinates;

                // Ajustar as coordenadas para garantir que o corte não saia da imagem:
                left = Math.max(0, left); // Garante que left não seja menor que 0
                top = Math.max(0, top); // Garante que top não seja menor que 0

                // Ajustar width e height para garantir que não excedam as dimensões da imagem:
                if (left + width > maxWidth) {
                    // width = maxWidth - left; // Ajusta a largura para não ultrapassar a borda direita
                }
                if (top + height > maxHeight) {
                    height = maxHeight - top; // Ajusta a altura para não ultrapassar a borda inferior
                }

                // Checar se as dimensões são válidas antes de desenhar
                if (width > 0 && height > 0) {
                    croppedCanvas.width = width;
                    croppedCanvas.height = height;

                    ctx.drawImage(
                        canvas,
                        left,
                        top,
                        width,
                        height,
                        0,
                        0,
                        width,
                        height
                    );

                    console.log(`Cropping with: left=${left}, top=${top}, width=${width}, height=${height}`);
                    console.log(`Canvas dimensions: width=${canvas.width}, height=${canvas.height}`);

                    // Converte o canvas em uma URL de imagem
                    croppedCanvas.toBlob((blob) => {
                        this.$emit('set-img', blob)
                    });
                } else {
                    console.error('Invalid dimensions for cropping:', width, height);
                }
               
            }
            */
        },

        cancelCrop() {
            this.$emit('reset-logo')
        }
    },
}
</script>

<style scoped=true>

.cropper {
	height: 400px;
	width: 400px;
	background: #DDD;
}
</style>
